import React from "react"
import Layout from "../../components/Layout"
import ContactDetails from "../../components/ContactDetails"

import classNames from "classnames/bind"
import styles from "./styles.module.scss"

export default function Contact({ pageContext: { language } }) {
	
	const cx = classNames.bind(styles);
  
  return(
  	<Layout
			lang={language}
			availableLanguages={["th", "en"]}
			title={"Contact Us"}
			pathname={"/contact/"}
			customTitle={true}
		>
			<div className={cx("grid")}>
				<div>
					<h4>
					{
						language === "th"
						? <React.Fragment>สถาบันวิจัยเศรษฐกิจ<span className="nobreak">ป๋วย อึ๊งภากรณ์</span></React.Fragment>
						: <React.Fragment>Puey Ungphakorn Institute <span className="nobreak">for Economic Research</span></React.Fragment>
					}
					</h4>
					<ContactDetails lang={language} />
				</div>
				<iframe
					src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3875.186207648875!2d100.4987418146626!3d13.767641690337186!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e29943f004853f%3A0x6f01511ff274d9d1!2sPuey%20Ungphakorn%20Institute%20for%20Economic%20Research!5e0!3m2!1sen!2sth!4v1615354350769!5m2!1sen!2sth"
					title="Map"
					frameBorder="0" style={{border:0, width: "100%", height: "400px"}}
					allowFullScreen=""
					aria-hidden="false"
				/>
			</div>
  	</Layout>
  )

}
